<template>
    <div>
        <div class="txt txt--xs txt--dark font-weight-bold mb-8px">적용가능 카테고리</div>
        <div v-for="(item, index) in items" :key="item._id" :class="index != 0 ? 'mt-6px' : ''">
            <router-link :to="item.to" class="d-inline-block">
                <v-breadcrumbs :items="item.items" class="pa-0" />
            </router-link>
        </div>
    </div>
</template>

<script>
import { initShopCoupon } from "@/assets/variables";
import { mapState } from "vuex";
export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    computed: {
        ...mapState(["categories"]),
        items() {
            return this.coupon.usage.target._categories
                .map((_id) => this.findCategory(_id))
                .map(({ parents = [], name, code }) => ({
                    items: [...parents.map(({ name }) => ({ text: name })), { text: name }],
                    to: {
                        path: "/shop/products",
                        query: { category: code },
                    },
                }));
        },
    },
    methods: {
        findCategory(_id, categories = this.categories) {
            let category = categories.find((item) => item._id == _id);
            if (category) return { ...category, parents: [...(category?.parents || [])] };

            let children = categories.flatMap(({ children = [], parents = [], ...parent }) => children.map((child) => (child ? { ...child, parents: [...parents, parent] } : child)));
            if (children.length) return this.findCategory(_id, children);
        },
    },
};
</script>

<style></style>
