var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-bold mb-8px"
  }, [_vm._v("적용가능 상품전시")]), _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item._id,
      attrs: {
        "cols": "auto"
      }
    }, [_c('router-link', {
      attrs: {
        "to": {
          path: `/shop/exhibitions/${item._id}`
        }
      }
    }, [_vm._v("· " + _vm._s(item.title))])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }