var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-bold mb-8px"
  }, [_vm._v("적용가능 카테고리")]), _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: item._id,
      class: index != 0 ? 'mt-6px' : ''
    }, [_c('router-link', {
      staticClass: "d-inline-block",
      attrs: {
        "to": item.to
      }
    }, [_c('v-breadcrumbs', {
      staticClass: "pa-0",
      attrs: {
        "items": item.items
      }
    })], 1)], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }